.App {
    margin-top: 0px;
    background-color: rgb(243, 245, 249);
    padding-left:0px;
    padding-right:0px;
    padding-bottom: 80px;
    box-shadow: 0px 0px 6px 3px #C4C4C4;
  }
  
  .App .navbar {
    background-color: rgb(38, 78, 198);
    height: 60px;
  }
  
  .App .navRight{
    font-size: 18px;
  }

  .App .navLeft {
    background-color: rgb(38, 78, 198);
  }
  
  .App .navbar-nav .nav-link {
    color: #FFFFFF;
    font-size: 14px;
  }
  
  .App .navbar-nav .active.nav-link {
    color: #FFFFFF;
  }
  
  .App  .navbar-nav .nav-link:hover {
    color: rgb(38,78,198);
    border-bottom-style: solid;
    border-bottom-color: rgb(38,78,198);
    border-bottom-width: 2px;
  }
  
  .App .navbar-brand {
    color: #FFFFFF;
    font-size:32px;
    padding-left:10px;
  }
  
  .App .navbar-brand:hover {
    color: #FFFFFF;
    font-size:32px;
    text-decoration: underline;
  }
  
  .largeHeader {
    font-size: 36px;
  }
  
  .smallHeader {
    font-size: 17px;
    font-weight: 600;
  }
  
  .largeParagraph {
    color: rgba(71,74,77,.8);
    font-size: 14.2px;
  }
  
  .smallParagraph {
    color: rgba(71,74,77,.8);
    font-size: 11.3px;
  }

  
  @media all and (min-width: 320px) {
    .container {
      max-width: 320px;
    }
    
    .footerBox {
      max-width: 320px;
    }
    
    .navbar-toggler {
      border-color: rgb(0,0,0);
      color:#FFFFFF
    }
    
    a.nav-link {
      background-color: rgb(38,78.198);
      border-style: solid;
      border-color: rgb(38,78,198);
      border-width: 5px;
    }
    
    a.nav-link:hover {
      background-color: rgb(243, 245, 249);
      color:rgb(38, 78, 198);
      border-style: solid;
      border-color: rgb(243, 245, 249);
      border-width: 5px;
      font-weight:bold;
    }
    
    .navRight {
      /* background-color: rgb(243, 245, 249); */
      padding-bottom: 10px;
    }
    
    .CabinButtonNav {
      margin-left: 0px;
    }
    
  }
  
  
  @media all and (min-width: 720px) {
    .container {
      max-width: 720px;
    }
    
    .footerBox {
      max-width: 720px;
    }
  }
  
  @media all and (min-width: 1024px) {
    .container {
      max-width: 1024px;
    }
    
    .footerBox {
      max-width: 1024px;
    }
  }
  
  .dropdown-item {
    color: rgb(38,78,198);
    font-weight: 500;
  }
  
  .CabinButtonNav {
    background-color: rgb(38, 78, 198);
    color: #FFFFFF !important;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    width: 100px;
    height: 36px;
    vertical-align: middle;
    box-shadow: none;
    margin-left: 15px;
  }
  
  .CabinButtonNav:hover {
    background-color: #FFFFFF;
    color: rgb(38, 78, 198) !important;
    font-size: 14px;
    border-style: solid !important;
    border-radius: 4px !important;
    border-color: rgb(38,78,198);
    text-align: center;
    width: 100px;
    height: 36px;
    font-weight: 600;
    box-shadow: none;
    margin-left: 15px;
  }
  
  .CabinButtonNav.active {
    background-color: rgb(38, 78, 198);
    color: #FFFFFF !important;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    width: 100px;
    height: 36px;
    vertical-align: middle;
    box-shadow: none;
    margin-left: 15px;
  }
  
  .CabinButtonNav.active:hover {
    background-color: #FFFFFF;
    color: rgb(38, 78, 198) !important;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    width: 100px;
    height: 36px;
    vertical-align: middle;
    box-shadow: none;
    margin-left: 15px;
  }