.homeHeader {
    color: rgb(38,78,198);
  }
  
  .homeParagraph{
    color: rgb(71,74,77);
  }
  
  @media all and (min-width: 320px) {
  
    .MainIntro {
      height: 515px;
    }
    
    .IntroContainer {
       margin-left:1000px;
       margin-right:10px;
    }
    
    .IntroText {
      margin-top: 20px;
      padding: 0px;
      text-align: center;
    }
    
    .IntroParagraph {
      width: 90%;
      margin: auto;
      padding-bottom: 30px;
    }
    
    .subIntroTitle {
      padding-right: 30px;
    }
    
    .IntroImageHolder {
      margin: auto;
      text-align: center;
    }
    
    .IntroImage {
      margin-top: 60px;
      margin: auto;
      text-align: center;
      padding-left: 0px;
      height: 185px;
      width: 250px;
    }
    
    .thirdDiv {
      height: 990px;
    }
    
    .worksHeader {
      text-align: center;
      padding-top: 14px;
      padding-bottom: 14px;
      margin-bottom: 0px;
      margin-left: 10px;
      margin-right: 10px;
    }
    
    .thirdDivRow {
      width:100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    
    .rowHeader {
      text-align: center;
      padding-top: 8px;
    }
    
    .rowText {
      text-align: center;
      font-size: 14px;
      padding-left: 3px;
      padding-right: 3px;
    }
    
    .worksPic {
      width: 218px;
      height: 126px;
      display: block;
      margin: auto;
    }
    
  }
  
  @media all and (min-width: 720px) {
  
    .MainIntro {
      height: 625px;
    }
    
    .IntroContainer.row {
       margin-left:50px;
       margin-right:50px;
    }
    
    .IntroText {
      margin-top: 20px;
      padding: 0px;
      text-align: center;
    }
    
    .IntroParagraph {
      width: 70%;
      margin: auto;
      padding-bottom: 30px;
    }
    
    .subIntroTitle {
      padding-right: 30px;
    }
    
    .IntroImageHolder {
      text-align: center;
      margin: auto;
      padding-right: 0px;
    }
    
    .IntroImage {
      margin-top: 60px;
      margin: auto;
      text-align: center;
      padding-left: 0px;
      height: 370px;
      width: 500px;
    }

    .thirdDiv {
      height: 490px;
    }
    
    .worksHeader {
      text-align: center;
      padding-top: 34px;
      padding-bottom: 34px;
      margin-bottom: 0px;
    }
    
    .thirdDivRow {
      width:100%;
      padding-left: 10px;
      padding-right: 10px;
      margin-left:0px;
      margin-right:0px;
    }

    .thirdDivRow.row {
        margin-left:auto;
        margin-right:auto;
    }
    
    .rowHeader {
      text-align: center;
      padding-top: 8px;
    }
    
    .rowText {
      text-align: center;
      font-size: 14px;
      padding-left: 3px;
      padding-right: 3px;
    }
    
    .worksPic {
      width: 218px;
      height: 126px;
      display: block;
      margin: auto;
    }
  }
  
  @media all and (min-width: 1024px) {
  
    .MainIntro {
      height: 500px;
    }
    
    .IntroText {
      margin-top: 380px;
      padding: 0px;
    }
    
    .subIntroTitle {
      padding-right: 30px;
    }
    
    .IntroImage {
      margin-top: 70px;
      height: 370px;
      width: 500px;
    }
    .thirdDiv {
      height: 490px;
    }
    
    .worksHeader {
      text-align: center;
      padding-top: 34px;
      padding-bottom: 34px;
      margin-bottom: 0px;
    }
    
    .thirdDivRow {
      width:1024px;
      padding-top: 20px;
      margin-left:0px;
      margin-right:0px;
    }

    .thirdDivRow.row {
        margin-left:auto;
        margin-right:auto;
    }
    
    .rowHeader {
      text-align: center;
      padding-top: 8px;
    }
    
    .rowText {
      text-align: center;
      font-size: 14px;
      padding-left: 3px;
      padding-right: 3px;
    }
    
  }