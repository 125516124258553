.footerBox {
    background-color: rgb(38,78,198);
    position:absolute !important;
    left:0;
    right:0;

}

.footerDiv {
    text-align: center;
    color: #FFF;
}

.footerText {
    color: #FFF
}