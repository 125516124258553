.CabinButton {
    background-color: rgb(38, 78, 198);
    color: #FFFFFF;
    border-radius: 4px;
    font-size: 18px;
    text-align: center;
    width: 120px;
    height: 44px;
    vertical-align: middle;
    box-shadow: none;
  }
  
  .CabinButton:hover {
    background-color: #FFFFFF;
    color: rgb(38, 78, 198);
    font-size: 18px;
    border-style: solid;
    border-radius: 4px;
    text-align: center;
    width: 120px;
    height: 44px;
    font-weight: 600;
    box-shadow: none;
  }